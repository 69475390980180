<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="位置名称" prop="location">
                <a-select placeholder="请选择标签" style="width: 100%" v-model="queryParam.label" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.LocationEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="广告名称" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入广告名称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="开始时间" prop="startTime">
                <a-date-picker style="width: 100%" @change="onDateChange" :showTime="true" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="结束时间" prop="endTime">
                <a-date-picker style="width: 100%" @change="onDateChange1" :showTime="true" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['config:banner:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['config:banner:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['config:banner:remove']">
          <a-icon type="delete" />删除
        </a-button>
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['config:banner:export']">
          <a-icon type="download" />导出
        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="startTime" slot-scope="text, record">
          {{ parseTime(record.startTime) }}
        </span>
        <span slot="endTime" slot-scope="text, record">
          {{ parseTime(record.endTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>

        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="location" slot-scope="text, record">
            <!-- 重写自定义了个字典组件（location）-->
           <custom-dict-tag :options="customDict.LocationEnum" :value="record.location"/>
        </span>
        <span slot="type" slot-scope="text, record">
            <!-- 重写自定义了个字典组件（标签）-->
           <custom-dict-tag :options="customDict.BannerTypeEnum" :value="record.type"/>
        </span>
        <span slot="jumpType" slot-scope="text, record">
            <!-- 重写自定义了个字典组件（标签）-->
           <custom-dict-tag :options="customDict.JumpTypeEnum" :value="record.jumpType"/>
        </span>
        <span slot="img" slot-scope="text, record">
          <div>
            <img v-for="imgurl in record.img.split(',')"
              v-if="record.img" :src="imgurl"  style="width:60px;height:62px;" @preview="handlePreview"/>
          </div>
        </span>
        <template slot="smallImg" slot-scope="text, record">
          <div>
            <img v-if="record.smallImg" :src="record.smallImg"  style="width:60px;height:62px;" />
          </div>
        </template>
        <span slot="audio" slot-scope="text, record">
          <div>
            <video v-if="record.audio" :src="record.audio"  style="width:60px;height:62px;" @preview="handleTalkVideoPreview"/>
          </div>
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['config:banner:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['config:banner:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['config:banner:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['config:banner:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listBanner, delBanner } from '@/api/config/banner'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
export default {
  name: 'Banner',
  components: {
    CreateForm,
    CustomDictTag
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        location: null,
        type: null,
        name: null,
        img: null,
        startTime: null,
        endTime: null,
        backgroundColor: null,
        smallImg: null,
        audio: null,
        jumpType: null,
        jumpInfo: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        /*{
          title: 'id',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '位置名称',
          dataIndex: 'location',
          ellipsis: true,
          scopedSlots: { customRender: 'location' },
          align: 'center'
        },
        {
          title: '类型',
          dataIndex: 'type',
          ellipsis: true,
          scopedSlots: { customRender: 'type' },
          align: 'center'
        },
        {
          title: '广告名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '高清图',
          dataIndex: 'img',
          ellipsis: true,
          scopedSlots: { customRender: 'img' },
          align: 'center'
        },
        /*{
          title: '小图',
          dataIndex: 'smallImg',
          ellipsis: true,
          scopedSlots: { customRender: 'smallImg' },
          align: 'center'
        },
        {
          title: '音频',
          dataIndex: 'audio',
          ellipsis: true,
          scopedSlots: { customRender: 'audio' },
          align: 'center'
        },*/
        {
          title: '跳转类型',
          dataIndex: 'jumpType',
          scopedSlots: { customRender: 'jumpType' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '类型名称',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
       /* {
          title: '背景色',
          dataIndex: 'backgroundColor',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '开始时间',
          dataIndex: 'startTime',
          scopedSlots: { customRender: 'startTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '结束时间',
          dataIndex: 'endTime',
          scopedSlots: { customRender: 'endTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    /** 查询banner配置列表 */
    getList () {
      this.loading = true
      listBanner(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    onDateChange(date, dateString){
      console.log(date, dateString)
      this.queryParam.startTime = dateString
    },
    onDateChange1(date, dateString){
      console.log(date, dateString)
      this.queryParam.endTime = dateString
    },
    handlePreview(file) {
      console.log("打开了")
      this.previewAvatar = file.url || file.preview;
      this.previewVisible = true;
      this.AUDIO = URL.createObjectURL(file);   // 拼接 URL + file 并转成 blob地址
    },
    async handleTalkVideoPreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewTalkImage = file.url || file.preview
      this.previewTalkVisible = true
    },
    handlePreviewvideo(file) {
      console.log("打开了")
      this.previewAvatar = file.url || file.preview;
      this.previewVisible = true;
      // 第一种，转成 base64码
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload=()=>{
        this.previewAvatar = reader.result
        console.log(reader.result,'地址')
      }
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        location: undefined,
        type: undefined,
        name: undefined,
        img: undefined,
        backgroundColor: undefined,
        smallImg: undefined,
        audio: undefined,
        jumpType: undefined,
        jumpInfo: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          return delBanner(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('config/banner/export', {
            ...that.queryParam
          }, `banner_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
